<template>
    <div>
        <a
            v-if="!isAuthenticated"
            @click.prevent="login"
            class="flex cursor-pointer items-center font-medium text-neutral-600 hover:text-black"
            :class="isAuthenticating ? 'invisible' : ''"
        >
            <span class="text-xs at1024:text-sm" data-testid="auth-login-button"
                >Sign in</span
            >
        </a>
        <user-menu v-if="isAuthenticated"></user-menu>
    </div>
</template>

<script setup>
import { useUserInfo } from '@/composables/useUserInfo';
import { ServiceRouter } from '@/service-router';
import UserMenu from './UserMenu';

const { isAuthenticated, isAuthenticating } = useUserInfo();
const serviceRouter = new ServiceRouter();

function login() {
    window.location.href = serviceRouter.eventManagerUrl('login', {
        intended_url: btoa(window.location.href),
    });
}
</script>
