<template>
    <header
        class="relative z-20 mx-auto max-w-wide px-4 at720:px-6 at1024:px-8"
    >
        <div
            class="flex flex-wrap items-center justify-between py-5 at1024:relative"
        >
            <div class="min-w-[13%] at900:w-20 at1024:w-auto">
                <a href="/" class="block">
                    <img
                        v-if="!isMainStorefront && logoUrl"
                        class="h-[40px] w-auto"
                        :src="logoUrl"
                        alt="Logo"
                    />
                    <template v-else>
                        <span class="sr-only">Elevent</span>
                        <img
                            class="h-[32px] w-auto"
                            :src="
                                assetUrl('/static/img/elevent_horz_color.svg')
                            "
                            alt="Elevent logo"
                            width="314"
                            height="77"
                        />
                    </template>
                </a>
            </div>

            <catalog-navigation
                :items="nav"
                :page-idx="activeTopNav"
                :active-group="activeGroup"
                class="hidden justify-center nav-break:flex"
            ></catalog-navigation>

            <div
                class="relative flex min-w-[13%] items-center justify-end gap-x-4"
            >
                <a
                    v-if="isMainStorefront"
                    href="/concierge"
                    class="hidden text-xs font-medium text-neutral-600 hover:text-black at768:block at768:py-0 at768:leading-6 at1024:text-sm"
                >
                    Let us help
                </a>
                <div>
                    <authentication></authentication>
                </div>
                <div class="hidden nav-break:block">
                    <button
                        class="rounded-full bg-gray-200 px-2 py-2 text-black"
                        @click.stop.prevent="openSearchModal"
                        aria-label="Search"
                        data-testid="search-field-icon"
                    >
                        <search-icon class="h-5 w-5"></search-icon>
                    </button>
                </div>
                <mobile-navigation
                    :items="nav"
                    :active-group="activeGroup"
                    class="nav-break:hidden"
                ></mobile-navigation>
            </div>
        </div>

        <div
            class="relative"
            v-if="
                showSubNav &&
                nav[activeTopNav]?.children.length &&
                nav[activeTopNav]?.menu_type !== 'flyout'
            "
        >
            <sub-navigation
                :items="nav[activeTopNav]?.children || []"
                class="z-20 hidden w-full flex-shrink-0 flex-wrap items-center justify-center border-t-2 border-gray-200 bg-white py-2 text-sm font-normal nav-break:flex"
            ></sub-navigation>
        </div>

        <div class="mb-4 nav-break:hidden">
            <search-box></search-box>
        </div>
    </header>
</template>
<script setup>
import { assetUrl } from '@/cdn';
import { useActiveNavGroup } from '@/composables/useActiveNavGroup';
import { useStorefront } from '@/composables/useStorefront';
import { SearchIcon } from '@heroicons/vue/solid';
import Authentication from 'components/Global/Header/Authentication';
import CatalogNavigation from 'components/Global/Header/CatalogNavigation';
import MobileNavigation from 'components/Global/Header/CatalogNavigation/MobileNavigation';
import SubNavigation from 'components/Global/Header/CatalogNavigation/SubNavigation';
import SearchBox from 'components/Global/Search/SearchBox';
import SearchModal from 'components/Global/Search/SearchModal';
import findIndex from 'lodash/findIndex';
import { ref } from 'vue';
import { useModal } from 'vue-final-modal';

const props = defineProps({
    nav: {
        type: Object,
        required: true,
    },
    logoUrl: {
        type: String,
        required: false,
    },
    showSubNav: {
        type: Boolean,
        required: false,
        default: true,
    },
});

const { activeNavGroup: activeGroup } = useActiveNavGroup();

const initialTopNav = findIndex(
    props.nav,
    (el) => el.group === activeGroup.value
);
const activeTopNav = ref(initialTopNav);

const { open: openSearchModal } = useModal({
    component: SearchModal,
});

const { isMainStorefront } = useStorefront();
</script>
